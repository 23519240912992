<template>
  <div class='container'>
    <div class="total">
      <p>共<b style="margin: 0 1.3333vw;">{{list.length}}</b>个课程</p>
    </div>
    <div class="list" v-if="list.length>0">
      <div class="list-content" v-for="item in list" :key="item.id" @click="toPage(item.url)">
        <van-image
          width="32.5333vw"
          height="27.2vw"
          :src="item.imgCourses"
          fit="cover"
          radius="2.6667vw"
        />
        <div style="flex:1;display: flex;flex-direction: column;justify-content: space-evenly;margin-left: 3.2vw;height: 27.2vw;">
          <p class="omit" style="font-size: 3.7333vw;font-weight: 550;-webkit-line-clamp:2">{{item.title}}</p>
          <div class="omit" style="color: #7d7d7d;font-size: 3.2vw;-webkit-line-clamp:2;display: flex;align-items: center;">
            <img src="@/assets/my/teacher.png" alt="" style="width: 10px;height: 10px;margin-right: 1.3333vw;">
            <p>直播讲师：{{item.authorName}}</p>
          </div>
          <div style="color: rgba(178, 178, 178, 1);font-size: 3.2vw;display: flex;align-items: center;">
            <img src="@/assets/my/time.png" alt="" style="width: 10px;height: 10px;margin-right: 1.3333vw;">
            <p>开播时间：{{ item.d }}{{ item.startTime }}</p>
          </div>
        </div>
      </div>
      <div ref="bottom" style="position: relative; height: .2667vw;"></div>
    </div>
    <van-empty description="暂无家庭教育课程" v-else></van-empty>
  </div>
</template>

<script>
import {bottomLoading} from '@/utils/util.js'
import {courses} from '@/api/learn.js'
export default {
  data(){
    return{
      list:[],
    }
  },
  created(){
    this.getList()
  },
  mounted(){
    // bottomLoading(this.$refs.bottom,this.loadNextPage)
  },
  methods:{
    async getList(){
      const res = await courses()
      this.list=res.data.list
    },
    toPage(url){
      location.href=url
    },
    async loadNextPage() {
      if(this.listQuery.pageNo<this.totalPageCount){
        this.listQuery.pageNo++
        const formData={}
        for (const el in this.listQuery) {
          if (this.listQuery[el] !== -1 && this.listQuery[el] !== '') {
              formData[el] = this.listQuery[el]
          }
        }
        const res = await learningList(formData)
        this.totalPageCount=res.data.totalPageCount
        this.total=res.data.recordCount
        this.list.push(...res.data.list)
      }
      // 在这里编写请求下一页数据的逻辑
      // 可以使用AJAX或其他方式向服务器发送请求
      // 将获取的数据添加到this.list数组中
      // 更新this.page变量为下一页的页码
    }
  }
}
</script>
<style lang='less' scoped>
.container{
  padding: 4vw 3.2vw 4vw 3.2vw;
  background-color: rgba(248, 248, 248, 1);
  min-height: 100%;
  .total{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2.9333vw;
  }
  .list{
    margin-top: 4vw;
    // padding: 0 1.8667vw;
    .list-content{
      padding: 1.3333vw;
      margin-bottom: 4vw;
      display: flex;
      align-items: center;
      background-color: #fff;
      border-radius: 2.6667vw;
    }
  }
  .popup{
    position: relative;
    padding: 5.8667vw 5.3333vw 13.8667vw 5.3333vw;
    .popup-list{
      height: 80vh;
      overflow: scroll;
      width: 100%;
      // margin-top: 6.6667vw;
      .option{
        display: flex;
        flex-direction: column;
        margin-bottom: 2.6667vw;
        color: #333;
        .options{
          // margin-top: 2.6667vw;
          display: flex;
          flex-wrap: wrap;
          .options-content{
            margin-right:3.2vw ;
            width: 19.2vw;
            height: 11.2vw;
            line-height: 11.2vw;
            text-align: center;
            background-color: #fff;
            border: .2667vw solid #bbb;
            &:nth-of-type(3n){
              margin-right: 0;
            }
            margin-top: 3.2vw;
          }
          .options-content.black{
            background-color: #000;
            color: #fff;
          }
        }
        .selectInput{
          position: relative;
          input{
            padding-right: 9.3333vw;
            width: 100%;
          }
          .arrow{
            position: absolute;
            top: 53%;
            right:2.4vw;
            transform: translateY(-50%);
          }
        }
        input{
          margin-top:1.3333vw ;
          padding: 0 2.6667vw;
          height: 11.4667vw;
          border: .2667vw solid rgba(187, 187, 187, 1);
        }
        p{
          font-weight: 550;
        }
      }
    }
    .btns{
      position: fixed;
      display: flex;
      align-items: center;
      width: 100%;
      left: 0;
      bottom: 0;
      button{
        flex: 1;
        font-size: 4.2667vw;
        color: #fff;
        height: 13.8667vw;
        background-color: rgba(161, 161, 161, 1);
      }
    }
  }
}
</style>